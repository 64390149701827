.toast {
  align-items: center;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 1rem;
  text-align: center;
  width: 100%;
}

.positive {
  background-color: var(--color-background-green);
}

.negative {
  background-color: var(--color-background-red);
}

.toast div {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.toast h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  text-shadow: var(--outset-shade);
}
