.options {
  border-radius: 0.25rem;
  box-shadow: var(--outset-shade);
  margin-block-end: 0.25rem;
  max-height: min(60dvh, 16.5rem);
  overflow: scroll;
  overflow: overlay;
  overscroll-behavior: none;
  width: 100%;
}

.options:focus-visible {
  outline: 0.125rem solid var(--color-text);
}

.options li {
  align-items: center;
  background-color: var(--color-background-bright);
  display: flex;
  height: 3rem;
  padding-inline: 1rem;
}

.options li[role="option"] {
  cursor: pointer;
}

.options li.candidate {
  background-color: var(--color-background-gray);
}

.options li:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.options li:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.options li span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
