.hint {
  align-items: start;
  border-radius: 0.25rem;
  display: flex;
  gap: 0.5rem;
  padding: 0.125rem 0.375rem;
  position: relative;
}

.hint:hover,
.hint:focus-visible,
.hint:focus-within {
  background-color: var(--color-background-bright);
}

.hint.exact {
  color: var(--color-green);
}

.hint.partial {
  color: var(--color-yellow);
}

.hint.lower,
.hint.higher,
.hint.mismatch {
  color: var(--color-red);
}

.icon {
  flex: 0 0 auto;
  margin-block-start: 0.3rem;
  font-size: 1rem;
}
