.input {
  align-items: center;
  background-color: var(--color-text);
  border-radius: 0.25rem;
  box-shadow: var(--inset);
  color: var(--color-background);
  cursor: text;
  display: inline flex;
  gap: 0.25rem;
  height: 3rem;
  justify-content: center;
  padding-inline: 0.75rem;
}

.input::-webkit-search-cancel-button {
  display: inline-block;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='%23242528'><path d='M49.6648 5.87522C55.0924 0.13696 63.8684 8.89823 58.1226 14.3331L40.5928 31.8632C40.5 31.9558 40.5 32.1235 40.5928 32.2162L58.1226 49.7462C63.6488 55.182 55.1077 63.7412 49.6628 58.2081L32.1331 40.678C32.0405 40.5852 31.8727 40.5852 31.7801 40.678L14.2504 58.2081C8.80677 63.7425 0.263624 55.1853 5.78862 49.7482L23.3184 32.2181C23.4094 32.1274 23.4094 31.9559 23.3184 31.8652L5.78862 14.3351C0.429546 8.88923 8.80139 0.51542 14.2484 5.87323C21.0942 12.7191 24.9323 16.5574 31.7781 23.4033C31.8707 23.4961 32.0385 23.4961 32.1311 23.4033C38.9784 16.5581 42.8175 12.7204 49.6648 5.87522Z' /></svg>")
    no-repeat center / 1rem;
  background-blend-mode: overlay;
  font-size: 1.5rem;
  cursor: pointer;
  width: 1em;
  height: 1em;
}
