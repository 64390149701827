.guess {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.guess h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  text-shadow: var(--outset-shade);
  padding-inline: 0.375rem;
}

.guess ul {
  display: flex;
  flex-direction: column;
}
