.score {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  justify-content: space-between;
  max-width: 40rem;
  width: 100%;
}

.score figure {
  display: flex;
  gap: 0.25rem;
}
