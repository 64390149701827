.tooltip {
  max-width: 70%;
  pointer-events: none;
  z-index: 10;
}

.tooltip:not(.active) {
  display: none;
}

.green .content {
  background-color: var(--color-background-green);
}

.yellow .content {
  background-color: var(--color-background-yellow);
}

.red .content {
  background-color: var(--color-background-red);
}

.pointer {
  display: block;
  color: var(--color-background-bright);
  font-size: 0.75rem;
  height: 1em;
  width: 1em;
}

.green .pointer {
  color: var(--color-background-green);
}

.yellow .pointer {
  color: var(--color-background-yellow);
}

.red .pointer {
  color: var(--color-background-red);
}

.content {
  background-color: var(--color-background-bright);
  border-radius: 0 0.25rem 0.25rem;
  box-shadow: var(--outset-shade);
  color: hsla(0, 0%, 100%, 0.75);
  padding: 0.5rem 0.75rem;
}

.content strong,
.content a:hover,
.content a:focus-visible {
  color: white;
}
