import { CSSProperties, ReactElement, cloneElement } from "react";

import * as classes from "./style.module.css";

import { ClassList } from "~/src/lib/classList";

const icons: Record<string, ReactElement> = {
  exact: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 9.14286C0.5 6.71802 1.46326 4.3925 3.17788 2.67788C4.8925 0.963263 7.21802 0 9.64286 0H55.3571C57.782 0 60.1075 0.963263 61.8221 2.67788C63.5367 4.3925 64.5 6.71802 64.5 9.14286V54.8571C64.5 57.282 63.5367 59.6075 61.8221 61.3221C60.1075 63.0367 57.782 64 55.3571 64H9.64286C7.21802 64 4.8925 63.0367 3.17788 61.3221C1.46326 59.6075 0.5 57.282 0.5 54.8571V9.14286Z" />
      <path
        d="M23.9254 47.0293L11.0076 34.135C7.85771 31.0119 12.8359 26.4735 15.7708 29.3814L26.307 39.8965L49.2482 17.0014C52.3857 13.8928 57.055 18.7416 54.0115 21.755L28.6886 47.027C27.3898 48.3241 25.226 48.3239 23.9254 47.0293Z"
        fill="#fff"
      />
    </svg>
  ),

  mismatch: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.14286C0 6.71802 0.963263 4.3925 2.67788 2.67788C4.3925 0.963263 6.71802 0 9.14286 0H54.8571C57.282 0 59.6075 0.963263 61.3221 2.67788C63.0367 4.3925 64 6.71802 64 9.14286V54.8571C64 57.282 63.0367 59.6075 61.3221 61.3221C59.6075 63.0367 57.282 64 54.8571 64H9.14286C6.71802 64 4.3925 63.0367 2.67788 61.3221C0.963263 59.6075 0 57.282 0 54.8571V9.14286Z" />
      <path
        d="M43.356 15.2055C46.8451 11.5166 52.4868 17.1489 48.7931 20.6427L37.524 31.912C37.4643 31.9716 37.4643 32.0794 37.524 32.139L48.7931 43.4083C52.3457 46.9027 46.855 52.4051 43.3547 48.8481L32.0856 37.5787C32.026 37.519 31.9182 37.519 31.8586 37.5787L20.5895 48.8481C17.0901 52.4059 11.598 46.9048 15.1498 43.4096L26.4189 32.1402C26.4775 32.0819 26.4775 31.9717 26.4189 31.9133L15.1498 20.644C11.7047 17.1431 17.0866 11.7599 20.5882 15.2042C24.9891 19.6052 27.4565 22.0726 31.8574 26.4735C31.9169 26.5332 32.0247 26.5332 32.0843 26.4735C36.4861 22.0731 38.9541 19.6059 43.356 15.2055Z"
        fill="#fff"
      />
    </svg>
  ),

  partial: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.143A9.143 9.143 0 0 1 9.143 0h45.714A9.143 9.143 0 0 1 64 9.143v45.714A9.143 9.143 0 0 1 54.857 64H9.143A9.143 9.143 0 0 1 0 54.857V9.143Z" />
      <path
        fill="#fff"
        d="M33.779 49.82a2.8 2.8 0 0 1-3.96 0L13.98 33.98a2.8 2.8 0 0 1 0-3.96l15.84-15.84a2.8 2.8 0 0 1 3.96 0l15.84 15.84a2.8 2.8 0 0 1 0 3.96L33.78 49.82Z"
      />
    </svg>
  ),

  higher: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.143A9.143 9.143 0 0 1 9.143 0h45.714A9.143 9.143 0 0 1 64 9.143v45.714A9.143 9.143 0 0 1 54.857 64H9.143A9.143 9.143 0 0 1 0 54.857V9.143Z" />
      <path
        fill="#fff"
        d="M30.464 11.609a2.243 2.243 0 0 1 3.072 0l11.78 11.294c1.438 1.378.458 3.8-1.537 3.8h-6.79a.559.559 0 0 0-.555.551v21.537c0 1.217-1.023 2.209-2.217 2.209h-4.434c-1.194 0-2.217-.992-2.217-2.209V27.254a.559.559 0 0 0-.554-.552h-6.79c-1.996 0-2.976-2.42-1.537-3.8L30.464 11.61Z"
      />
    </svg>
  ),

  lower: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.143A9.143 9.143 0 0 1 9.143 0h45.714A9.143 9.143 0 0 1 64 9.143v45.714A9.143 9.143 0 0 1 54.857 64H9.143A9.143 9.143 0 0 1 0 54.857V9.143Z" />
      <path
        fill="#fff"
        d="M33.536 52.391a2.243 2.243 0 0 1-3.072 0l-11.78-11.294c-1.438-1.378-.458-3.8 1.537-3.8h6.79a.559.559 0 0 0 .555-.551V15.209c0-1.216 1.023-2.209 2.217-2.209h4.434c1.194 0 2.217.992 2.217 2.209v21.537c0 .304.256.552.554.552h6.79c1.996 0 2.976 2.42 1.537 3.8L33.536 52.39Z"
      />
    </svg>
  ),

  heart: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.07308 6.01008C19.7685 0.669111 28.561 7.15523 32 11.7358C35.439 7.15523 44.2315 0.669111 54.9269 6.01008C68.6831 12.881 65.244 30.0582 54.9269 40.3645C49.8922 45.394 41.0263 54.2506 34.5426 59.1771C33.8183 59.7267 32.9297 60.0163 32.0204 59.9993C31.111 59.9822 30.2339 59.6595 29.5308 59.0831C23.5858 54.2896 14.0368 45.323 9.07308 40.3645C-1.24404 30.0582 -4.68308 12.881 9.07308 6.01008Z" />
    </svg>
  ),

  party: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#FCC419"
        d="M30.815 64C51.36 64 61.63 50.204 61.63 33.185c0-17.02-10.269-30.815-30.815-30.815C10.268 2.37 0 16.166 0 33.185 0 50.205 10.268 64 30.815 64Z"
      />
      <path
        fill="#E64980"
        d="M60.444 8.889a1.778 1.778 0 0 0-3.555 0c0 3.086-2.586 5.333-5.333 5.333a1.778 1.778 0 1 0 0 3.556c4.546 0 8.889-3.68 8.889-8.89ZM17.779 53.926a1.778 1.778 0 1 0-3.556 0c0 3.086-2.586 5.333-5.333 5.333a1.778 1.778 0 1 0 0 3.556c4.546 0 8.889-3.679 8.889-8.89Z"
      />
      <path
        fill="#262626"
        d="M16.55 28.895c.114-.583.374-1.304.87-1.842.426-.465 1.18-.979 2.726-.979 1.524 0 2.299.526 2.745 1.017.516.569.765 1.292.848 1.759a2.37 2.37 0 0 0 4.67-.81 8.4 8.4 0 0 0-2.015-4.142c-1.356-1.493-3.411-2.565-6.246-2.565-2.806 0-4.852 1.022-6.22 2.513-1.299 1.413-1.823 3.065-2.034 4.148a2.37 2.37 0 1 0 4.656.9Zm23.704 0c.113-.583.374-1.304.87-1.842.426-.465 1.18-.979 2.728-.979 1.522 0 2.297.526 2.742 1.017.52.569.768 1.292.849 1.759a2.37 2.37 0 0 0 4.67-.81 8.398 8.398 0 0 0-2.015-4.142c-1.356-1.493-3.411-2.565-6.246-2.565-2.807 0-4.852 1.022-6.22 2.513-1.299 1.413-1.823 3.065-2.032 4.148a2.372 2.372 0 0 0 1.866 2.833 2.37 2.37 0 0 0 2.788-1.932Z"
      />
      <path
        fill="#228BE6"
        d="M52.148 2.37a2.37 2.37 0 1 1-4.74 0 2.37 2.37 0 0 1 4.74 0ZM14.222 45.037a2.37 2.37 0 1 0 0-4.74 2.37 2.37 0 0 0 0 4.74ZM54.52 64a2.37 2.37 0 1 0 0-4.74 2.37 2.37 0 0 0 0 4.74Zm.76-37.926a7.872 7.872 0 0 0-7.872 7.872v.306a6.044 6.044 0 0 0 6.045 6.044 4.623 4.623 0 0 0 4.622-4.622v-.711a1.778 1.778 0 1 0-3.555 0v.711a1.067 1.067 0 0 1-1.067 1.067 2.488 2.488 0 0 1-2.489-2.49v-.305a4.317 4.317 0 0 1 4.316-4.316h.425a4.74 4.74 0 0 1 4.74 4.74v.856a7.441 7.441 0 0 1-7.44 7.44H37.926a1.778 1.778 0 1 0 0 3.556h15.078A10.996 10.996 0 0 0 64 35.226v-.856a8.297 8.297 0 0 0-8.296-8.296h-.425Z"
      />
      <path
        fill="#262626"
        d="M34.773 35.366a2.37 2.37 0 1 0 0 4.74 1.232 1.232 0 0 1 0 2.466 2.37 2.37 0 0 0 0 4.74 1.233 1.233 0 1 1 0 2.466 2.37 2.37 0 0 0 0 4.74 5.973 5.973 0 0 0 4.765-9.576 5.973 5.973 0 0 0-4.765-9.576Z"
      />
    </svg>
  ),

  dead: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#FCC419"
        d="M32 62c20.003 0 30-13.432 30-30.002C62 15.432 52.003 2 31.998 2 11.998 2 2 15.432 2 32c0 16.568 9.995 30 30 30Z"
      />
      <path
        fill="#262626"
        d="M13.126 17.676a2.1 2.1 0 0 1 1.511-.648 2.1 2.1 0 0 1 1.511.648l3.833 3.97 3.832-3.97a2.14 2.14 0 0 1 .694-.496 2.073 2.073 0 0 1 1.653-.015c.263.111.501.276.702.484.2.207.36.455.467.727a2.283 2.283 0 0 1-.015 1.712 2.22 2.22 0 0 1-.479.719l-3.832 3.97 3.832 3.97c.39.418.605.977.6 1.558a2.255 2.255 0 0 1-.626 1.547c-.396.41-.932.643-1.492.648a2.1 2.1 0 0 1-1.504-.622l-3.832-3.97-3.833 3.97a2.098 2.098 0 0 1-1.503.622 2.101 2.101 0 0 1-1.493-.648 2.255 2.255 0 0 1-.626-1.547c-.005-.58.21-1.14.6-1.558l3.832-3.97-3.832-3.97a2.255 2.255 0 0 1-.626-1.566c0-.587.225-1.15.626-1.565Zm34.2 0c.196-.211.432-.38.693-.496a2.073 2.073 0 0 1 1.653-.015c.263.111.501.276.702.484.2.207.36.455.467.727a2.283 2.283 0 0 1-.014 1.712 2.22 2.22 0 0 1-.48.719l-3.832 3.97 3.832 3.97c.39.418.605.977.6 1.558a2.255 2.255 0 0 1-.626 1.547c-.396.41-.932.643-1.492.648a2.1 2.1 0 0 1-1.504-.622l-3.832-3.97-3.833 3.97a2.098 2.098 0 0 1-1.503.622 2.101 2.101 0 0 1-1.493-.648 2.255 2.255 0 0 1-.626-1.547c-.005-.58.21-1.14.6-1.558l3.832-3.97-3.832-3.97a2.258 2.258 0 0 1-.6-1.557c.005-.581.23-1.137.626-1.547a2.1 2.1 0 0 1 1.493-.648c.56-.006 1.1.218 1.503.621l3.833 3.97 3.832-3.97Z"
      />
      <path
        fill="#852C2C"
        d="M23 47.25c0-2.188.895-4.286 2.49-5.834A8.632 8.632 0 0 1 31.5 39c2.254 0 4.416.87 6.01 2.416A8.129 8.129 0 0 1 40 47.25a8.129 8.129 0 0 1-2.49 5.834A8.632 8.632 0 0 1 31.5 55.5a8.632 8.632 0 0 1-6.01-2.416A8.129 8.129 0 0 1 23 47.25Z"
      />
    </svg>
  ),

  circle: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 15C27.4913 15 23.1673 16.7911 19.9792 19.9792C16.7911 23.1673 15 27.4913 15 32C15 36.5087 16.7911 40.8327 19.9792 44.0208C23.1673 47.2089 27.4913 49 32 49C36.5087 49 40.8327 47.2089 44.0208 44.0208C47.2089 40.8327 49 36.5087 49 32C49 27.4913 47.2089 23.1673 44.0208 19.9792C40.8327 16.7911 36.5087 15 32 15ZM4 32C4 16.536 16.536 4 32 4C47.464 4 60 16.536 60 32C60 47.464 47.464 60 32 60C16.536 60 4 47.464 4 32Z" />
    </svg>
  ),

  flash: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.3461 0.772574L13.267 33.6852C12.65 34.5613 13.1602 35.5653 14.2043 35.5653H23.696C24.5266 35.5653 24.8825 35.8967 24.8825 36.7492V62.234C24.8825 63.9151 26.9232 64.6491 27.8961 63.2995L50.719 32.9251C51.3597 32.049 50.8424 30.8296 49.7983 30.8296H41.493C40.3066 30.8296 39.1201 29.6457 39.1201 28.4618V1.23193C39.1201 0.0480221 37.3167 -0.600759 36.3461 0.772574Z" />
    </svg>
  ),

  x: (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.6648 5.87522C55.0924 0.13696 63.8684 8.89823 58.1226 14.3331L40.5928 31.8632C40.5 31.9558 40.5 32.1235 40.5928 32.2162L58.1226 49.7462C63.6488 55.182 55.1077 63.7412 49.6628 58.2081L32.1331 40.678C32.0405 40.5852 31.8727 40.5852 31.7801 40.678L14.2504 58.2081C8.80677 63.7425 0.263624 55.1853 5.78862 49.7482L23.3184 32.2181C23.4094 32.1274 23.4094 31.9559 23.3184 31.8652L5.78862 14.3351C0.429546 8.88923 8.80139 0.51542 14.2484 5.87323C21.0942 12.7191 24.9323 16.5574 31.7781 23.4033C31.8707 23.4961 32.0385 23.4961 32.1311 23.4033C38.9784 16.5581 42.8175 12.7204 49.6648 5.87522Z" />
    </svg>
  ),
};

type Props = {
  name: keyof typeof icons;
  className?: string;
  size?: string;
  color?: string;
  style?: CSSProperties;
};

export function Icon({ name, className, style, size, color }: Props) {
  const classList = new ClassList(classes.icon);
  if (className) {
    classList.add(className);
  }
  return cloneElement(icons[name], {
    className: String(classList),
    style: { fontSize: size, color, ...style },
  });
}
