.header {
  align-items: center;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
}

.content {
  display: grid;
  flex: 1 0 auto;
  padding-block-end: 1rem;
}

.tutorial {
  color: var(--color-background-gray);
  font-size: 2rem;
  font-weight: bold;
  place-self: center;
  text-align: center;
}

.footer {
  align-items: center;
  background-color: var(--color-background);
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 10;
}

.copy {
  color: var(--color-text-dimmed);
  font-size: 0.75rem;
  padding-block: 0.5rem;
  text-align: center;
}

.copy a {
  font-weight: bolder;
}
