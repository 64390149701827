.history {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  max-width: 40rem;
  padding-inline: 1rem;
  width: 100%;
  place-self: start center;
}

.item {
  align-items: start;
  display: flex;
  gap: 0.375rem;
}

.item > * {
  flex: 0 1 100%;
}

.attempt {
  background-color: var(--color-background-bright);
  border-radius: 0.75em;
  color: var(--color-background);
  flex: 0 0 1.5em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5em;
  text-align: center;
}
