.button {
  align-items: center;
  background-color: var(--color-text-placeholder);
  border-radius: 0.25rem;
  box-shadow: var(--outset-shade), var(--outset-light);
  color: #fff;
  cursor: pointer;
  display: inline flex;
  gap: 0.25rem;
  height: 3rem;
  justify-content: center;
  line-height: 1.25;
  padding-inline: 1rem;
}

.button:not(:disabled):active {
  box-shadow: var(--inset);
  translate: 0.125rem 0.125rem;
}

.button.blue {
  background-color: var(--color-blue);
}

.button.red {
  background-color: var(--color-red);
}

.button.green {
  background-color: var(--color-green);
}

.button.yellow {
  background-color: var(--color-yellow);
}

.button:disabled {
  background-color: var(--color-background-gray);
}

.button:focus-visible {
  outline: 0.125rem solid var(--color-text);
}
